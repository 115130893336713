export default {
  "menu": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новинки"])},
    "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные"])},
    "slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слоты"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-дилеры"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столы"])},
    "fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любимые"])},
    "tournaments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турниры"])},
    "bonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусы"])},
    "achievements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достижения"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровни"])},
    "jackpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джекпоты"])},
    "wins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Победители"])},
    "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промо"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])}
  },
  "auth": {
    "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])}
  },
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "cashback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashback"])},
  "cashdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касса"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провайдеры"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти игру..."])},
  "online-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-поддержка"])},
  "we-social-networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы в социальных сетях"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "all-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All games"])},
  "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
  "users-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winners"])},
  "win-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo"])},
  "slots-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема слотов"])},
  "summer-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Летние игры"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игры"])},
  "game-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра дня"])},
  "cash": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой профиль"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История ставок"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
    "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
    "profile-tab": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои данные"])},
      "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верификация"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "all-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All levels"])},
      "next-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next level"])},
      "next-level-bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the next level you will receive:"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your"])},
      "to-level-left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["До уровня «", _interpolate(_named("level")), "»<br />осталось: ", _interpolate(_named("points")), " очков"])},
      "account-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account status:"])},
      "not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
      "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])}
    },
    "finance-tab": {
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash In"])},
      "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments history"])},
      "bids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bids history"])},
      "select-payway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method:"])},
      "select-amount-or-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or input an amount:"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "add-money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add money"])},
      "take-money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take cash"])}
    },
    "promo-tab": {
      "bonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусы"])},
      "cashback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кешбек"])},
      "promocode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промокод"])}
    }
  },
  "USD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "RUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["₽"])},
  "levels": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новичок"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронзовый"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серебряный"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Золотой"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платиновый"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бриллиантовый"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip1"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip2"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip3"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip4"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip5"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip6"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip7"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip8"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip9"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip10"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super VIP"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легендарный"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премиум"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хайроллер"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миллионер"])}
  },
  "vendorTitles": {
    "greentube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greentube"])},
    "igrosoft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игрософт"])},
    "netent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NetEnt"])},
    "pragmatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PragmaticPlay"])},
    "desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настольные"])},
    "yggdrasil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yggdrasil"])},
    "playngo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playngo"])},
    "megajack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мегаджек"])},
    "microgaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microgaming"])},
    "belatra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belatra"])},
    "unicum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unicum"])},
    "betinhell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betinhell"])},
    "pushgaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PushGaming"])}
  },
  "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный логин и пароль"])},
  "user barred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт заблокирован"])},
  "payments": {
    "moneyback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат"])},
    "wager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус"])},
    "api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система"])},
    "apitest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
    "webmoney_wmz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneypiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneyfk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneydirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneytrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneywmrik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneywmuik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "webmoneyuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webmoney"])},
    "qiwifk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "qiwitrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "qiwiuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "qiwipiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "qiwienot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "qiwirubpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "alipayqiwi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi"])},
    "yandextrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yandexrurik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yandexuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yandexfk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yandexenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yandexpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yoomagnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "yoop2prubpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмани"])},
    "kspiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "beelinepiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "mtspiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "megafonpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "tele2piastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "btcuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "ethereumuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "btccashenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "litecoinenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "btcpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "ethpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "tetherpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodeusdttrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodeusdterc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodebtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodeusdc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodeeth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "exnodeton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "rubpayusdttrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "sberbank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "alfabank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "alfabanktrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "psbanktrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "alfabankpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "psbankpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "perfectmoneyusdtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PerfectM"])},
    "perfectmoneyuahik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PerfectM"])},
    "perfectmoneyusdpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PerfectM"])},
    "terminalrurtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Терминал"])},
    "terminalrur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Терминал"])},
    "terminalrurpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Терминал"])},
    "advcashrurik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advcash"])},
    "advcashpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advcash"])},
    "payeerfk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payeer"])},
    "payeerpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payeer"])},
    "payeerenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payeer"])},
    "okpayrubtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okpay"])},
    "okpayrubpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okpay"])},
    "applerurenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple/SM"])},
    "samsungrurenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple/SM"])},
    "fkwalletfk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FKWallet"])},
    "piastrixpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piastrix"])},
    "qiwicardmagnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiwi P2P"])},
    "skycrypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "magnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "paylama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "cardrubpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "rubpayp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "brpayp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "brpayp2psbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "alipayp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "euphoriap2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
    "visarur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarurik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarurtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahprivattrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahprivatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahnotprivatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahnotprivatik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "mastercarduahnotprivatik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarurfk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarurpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visauahprivatpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarurenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "visarursngenot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "mastercarduahprivatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "mastercarduahnotprivatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "mastercardrurik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "carduahtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "cardprivatuahtrio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "carduahpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "cardprivatuahpiastrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "carddirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "mirrurik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "brpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "brpay2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "qubipay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "billline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "yeezypay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "rubpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "onepayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "niko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "gex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "swiftpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "brpayhost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "rockspay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "alipaycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "paycosp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "paycosp2pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "paycossbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "paycossbpcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "paycosqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "paycosoutsber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "paycosoutsbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "paycosout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "sbprubpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "antrpayp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "antrpaysbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "antrpayoutcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "pagocard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "pagosbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repaysbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "repaysbpftd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "repaysberpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repaysberpayftd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "euphoriasbpout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "euphoriasbpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "euphoriacardout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "rubpayacc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "brpayacc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repayoutsbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "cascadeoutsbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "cascadeoutp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repayoutp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "repayoutacc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "moneyback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "Payin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод"])},
    "Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
    "infocassa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "w1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "cash4wm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "trio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "enot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "brpaysbptbank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "brpaysbptbankftd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "brpaysbpsber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "brpay2outsbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "antrpayp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "antrpaysbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "luckyp2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
    "luckysbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
    "luckyacc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет"])}
  }
}